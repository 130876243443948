<template>
    <div class="page">
        <div class="left" @click="openAI"><img style="width: 100px;height: 100px;" src="../assets/imgs/robot.png"></div>
        <el-dialog title="AI助理" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
            <iframe
                :src="`http://localhost:8080/Home?s=江苏云馆配&u=admin_1&t=BCAC994C-BBC6-4B56-B168-7C4DF1C2AB59&a=A1151126-2A6A-4582-9512-DB93747C9FEE&k=ForTest^-^&DataUrl=https://aidev.91onix.com/api/test/TestSlowReportData`"
                width="100%" height="650px" title="AI助理" frameborder="no" border="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Index',
    components: { },
    data: function(){
        return {
            time: 0,
            dialogVisible: false
        }
    },
    methods: {
        openAI: function(){
            this.time = new Date().getTime();
            this.dialogVisible = true;
        },
        handleClose: function(){
            this.dialogVisible = false;
        }
    }
}
</script>
